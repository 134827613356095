<template id="new">
    <div align="center">
      <ModalForm :visible="show" @close="show = false" :message="modalMsg" />
      <div class="card col-md-8 rounded shadow-lg" style="text-align: left">
        <div class="card-body">
          <div class="card-title">
            <h1 class="">{{ title }}</h1>
          </div>
          <div class="col-4">
          </div>
          <!--
          <div style="margin-top:0cm;margin-left:8cm;">
            <div style="display: inline">
              <div style="display: inline-table">
                <div style="display: inline-block">
                  <img src="siged2.png" style="width: 40px; height: 30px; max-width:100%; height:auto" />
                </div>
                <div style="display: inline-block">
                  <div style="width:4cm">Compania</div>
                  <div style="width:4cm">Tipo Correspondencia</div>
                  <div style="width:4cm">Radicado</div>
                </div>
              </div>
            </div>
            <div>Fecha</div>
            <div>Dependencia</div>
            <div>Serie</div>
            <div>Anexos</div>
            <div>No. Folios</div>
            <div align="right" class="siged">siged</div>
          </div>
          -->
          <div class="text-right text-info">
            <label>(*) Campo obligatorio</label>
          </div>
          <form v-on:submit.prevent="saveItem">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4">Tipo de solicitante (*):</label>
                  <select v-model="pqrs.tso_id" ref="tso_id" v-on:change="setTposolic" class="form-control col-md-8" autofocus required :disabled="relation || show">
                    <option v-for="tposolic in tposolics" v-bind:value="tposolic._id">
                      {{ tposolic.tso_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>              
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Tipo de identificación (*):</label>
                  <select v-model="pqrs.dcm_tpoid" ref="dcm_tpoid" class="form-control col-md-8" required :disabled="relation || show">
                    <option v-for="tpoid in tpoids2" v-bind:value="tpoid._id">
                      {{ tpoid.tid_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">No. de identificación (*):</label>
                  <input type="number" min="1000000" max="9999999999" class="form-control col-md-8" v-model="pqrs.dcm_identificacion" v-bind:disabled="relation || show" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Nombre completo de persona o empresa (*):</label>
                  <input type="text" class="form-control col-md-8" v-model="pqrs.dcm_entidad" v-bind:disabled="relation || show" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Ciudad o municipio (*):</label>
                  <!--
                  <input type="text" class="form-control col-md-8" v-model="pqrs.dcm_ciudad" v-bind:disabled="relation || show" required>
                  -->
                  <select2ajax id="ciud_id" class="col-md-8" v-model="pqrs.ciud_id" :value="pqrs.dcm_ciudad" :url="'/ciudades/select'" v-on:select="setCiudad" v-bind:disabled="relation || show" v-bind:required="true" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">No. Teléfono (*):</label>
                  <input type="number" min="1000000" max="9999999999" class="form-control col-md-8" v-model="pqrs.dcm_telefono" v-bind:disabled="relation || show" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Dirección:</label>
                  <input type="text" class="form-control col-md-8" v-model="pqrs.dcm_direccion" v-bind:disabled="relation || show">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Correo electrónico (*):</label>
                  <input type="email" class="form-control col-md-8" v-model="pqrs.dcm_email" v-bind:disabled="relation || show" required>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4">Medio preferido para recibir la respuesta (*):</label>
                  <select v-model="pqrs.mdr_id" ref="mdr_id" class="form-control col-md-8" v-bind:disabled="show" required>
                    <option v-for="medioresp in medioresps" v-bind:value="medioresp._id">
                      {{ medioresp.mdr_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Tipo de Solicitud (*):</label>
                  <select v-model="pqrs.tpq_id" ref="tpq_id" class="form-control col-md-8" v-bind:disabled="show" required>
                    <option v-for="tpopqr in tpopqrs" v-bind:value="tpopqr._id">
                      {{ tpopqr.tpq_nmbre }}
                    </option>
                  </select>
                </div>
              </div>
            </div>    
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Asunto (*):</label>
                  <textarea rows="1" class="form-control col-md-8" v-model="pqrs.dcm_asunto" v-bind:disabled="show" required></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Mensaje o Descripción de la solicitud (*):</label>
                  <textarea rows="4" class="form-control col-md-8" v-model="pqrs.dcm_mensaje" v-bind:disabled="show" required></textarea>
                </div>
              </div>
            </div>
            <hr>
            <div class="row" id="_adjuntos">
              <div class="col-md-12">
                <div class="form-group">
                  <label><b style="color:darkblue">ARCHIVOS ADJUNTOS</b></label>
                  <!--
                  <div class="custom-file" v-if="!show">
                  -->
                  <div class="custom-file">
                    <input type="file" name="adjunto" id="files" ref="files" v-on:change="addFiles" multiple class="custom-file-input">
                    <label class="custom-file-label" for="files"><plus-circle-icon class="custom-class"></plus-circle-icon>Clic aquí para agregar archivos</label>
                  </div>
                  <br>
                  <div class="large-12 medium-12 small-12 cell">
                    <table class="table table-bordered">
                      <tr>
                        <th>#</th>
                        <th></th>
                        <th>Nombre de Archivo</th>
                        <th></th>
                      </tr>
                      <tr v-for="(i, index) in files">

                        <td>{{ index + 1 }}</td>
                        <td style="text-align:center">
                          <div v-if="i.uploaded === '1'" class="progress">
                            <div class="progress-bar" role="progressbar" :style.prop="percentageValue(i.percentage)" :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">Cargando</div>
                          </div>                                                                       
                          <!--
                          <img v-if="i.uploaded === '1'" src="/images/loading.gif" />
                          -->
                          <span v-if="i.uploaded === '2'">Error</span>
                          <span v-if="i.uploaded === '0'">
                            <div v-if="isPdf(i.adj_nmbre)">
                              <a href="#" v-on:click.prevent="viewPdf(i.adj_nmbre, i.temp, index)">
                                <i class="far fa-file-pdf" style="font-size:18px;color:red"></i>
                              </a>
                              <viewpdf v-bind:pdfFile="pdfFile"></viewpdf>
                              <img v-if="i.download" src="/images/loading.gif" />
                            </div>
                            <div v-if="!isPdf(i.adj_nmbre)">                            
                              <a href="#" v-on:click.prevent="downloadFile(i.adj_nmbre, i.temp, true, index)">
                                <i class="fas fa-download" style="font-size:18px;color:blue"></i>
                              </a>
                              <div v-if="i.download" class="progress">
                                <div class="progress-bar bg-success" role="progressbar" :style.prop="percentageValue(i.downloadPercentage)" :aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">Descargando</div>
                              </div>                                                                       
                            </div>
                          </span>
                        </td>
                        <td>{{ i.adj_nmbre }}</td>
                        <td style="text-align:center"><a href="#" v-on:click.prevent="deleteFile(index)"><i class="fas fa-trash" style="font-size:16px"></i></a></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="chequeAutorizar" required >
              <label class="form-check-label" for="chequeAutorizar">
                <p>Acepto la <a v-bind:href="$store.state.item.pqr_urlptdp" target="_blank" class="text-primary">política de tratamiento de datos personales</a>.</p>
                <p>Al hacer clic el botón enviar, usted acepta la remisión de la PQRS a la entidad. Sus datos serán recolectados y tratados conforme con la Política de Tratamiento de Datos. En la opción consulta de PQRS podrá verificar el estado de la respuesta.</p>
                <p>
                  En caso que la solicitud de información sea de naturaleza de identidad reservada, deberá efectuar el respectivo trámite ante la Procuraduría General de la Nación, haciendo clic en el siguiente link: 
                  <a href="https://www.procuraduria.gov.co/SedeElectronica/tramites/tramite.do?formAction=btShow&t=50012&s=0#no-back-button" target="_blank" class="text-primary">Procuraduría General de la Nación.</a>                  
                </p>
                <p>Se deberá indicar los términos que aplican en la presentación de quejas anónimas, para lo cual, se deben indicar las condiciones para aceptarlas conforme con la siguiente normativa: artículo 38 de la Ley 190 de 1995; artículo 69; de la Ley 734 de 2002 y artículo 81 de la Ley 962 de 2005.</p>
              </label>
            </div>
            <br />
            <div v-if="message">
              <p class="alert alert-warning" role="alert">{{ message }}</p>
            </div>
            <div class="row justify-content-around" style="text-align: center">
              <div class="col-4">
                <label class="col-md-12 col-form-label">Revise sus datos antes de enviarlos</label>
                <button type="submit" class="btn btn-primary" v-on:click="setClose(true)" v-bind:disabled="show">{{ buttontext }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
<script>
import ModalForm from '../modal/ModalForm.vue';
import select2ajax from '../select2/Select2Ajax.vue';
import { PlusCircleIcon, DeleteIcon } from 'vue-feather-icons';
import moment from 'moment';
import ViewPdf from './../doc/ViewPdf.vue';

export default {
  components: {
    ModalForm,
    PlusCircleIcon, DeleteIcon,
    'viewpdf': ViewPdf,
    select2ajax
  },
  data(){
    return{
      add: true, //Create a new document
      close: true, // Close the page and return to list
      title: '',
      pqrs: {},
      item: {},
      message: '',
      tpoids: [],
      tpoids2: [],
      tpopqrs: [],
      buttontext: '',
      files: [],
      adjuntos: new FormData(),
      isMail: true,
      url: '',
      modalMsg: '',
      show: false,
      ready: false,
      pdfFile: {},
      dep_cdgo: '',
      ser_cdgo: '',
      codigo_archivo: '',
      cmp: '',
      usu: '',
      usuname:'',
      timestamp: 0,
      tposolics: [],
      medioresps: [],
      relation: false
    }
  },
  computed: {
    company() {
      return this.$store.state.company
    },
    user() {
      return this.$store.state.user
    },
    "filesColumns": function filesColumns(){
      if (this.files.length == 0){
        return [];
      }
      return Object.keys(this.files[this.files.length-1]);
    }
  },
  created: function() {
    this.cmp = this.$store.state.company;
    this.usu = this.$store.state.item.usu_id;
    this.usuname = 'Web';
    this.fetchTimeStamp();

    this.fetchTpoid();
    this.fetchTpopqrs();
    this.fetchTposolic();
    this.fetchMedioresp();
    this.add = true; // this.$route.params.add;
    if (this.add){
      this.title = 'Crear solicitud';
      this.buttontext = 'Enviar datos';
      this.pqrs.adjuntos = [];

      let id_relation = this.$route.params?.pqrs?._id || ''

      if (id_relation) this.get_relation(id_relation);

      /* INICIALIZAR DATOS */
      /*
      this.pqrs.dcm_entidad = 'Stephen Hawking';
      this.pqrs.dcm_identificacion = '91000000';
      this.pqrs.dcm_ciudad = 'Londres';
      this.pqrs.dcm_direccion = 'Calle 00 # 00-00';
      this.pqrs.dcm_telefono = '88888888888';
      this.pqrs.dcm_email = 'ewarias@yahoo.com';
      this.pqrs.dcm_asunto = 'Solicitud de información';
      this.pqrs.dcm_mensaje = 'Por favor solicito el suministro de la información.';
      this.pqrs.dcm_tpoid = this.tpoids[0]._id;
      this.pqrs.tpq_id = this.tpopqrs[0]._id;
      */
      /******************** */
    } else {
      this.title = 'Editar una PQRS';
      this.buttontext = 'Guardar y cerrar';
      this.gettingItem = true;
      this.getItem(this.$route.params.id);
    }
  },
  mounted: function() {

  },
  destroyed: function() {

  },
  methods: {
    setClose(close){
      this.close = close;
    },
    saveItem(){
      /*
      if (this.files.length <= 0){
        alert('Debe agregar por lo menos un archivo con la solicitud PQRS escaneada.');
        return;
      }
      */

      var n = 0;
      for (var i = 0; i < this.files.length; i++){
        if (this.files[i].uploaded == '0'){
          n++;
        }
      }
      if (n < this.files.length){
        alert('Espere por favor, no se han cargado todos los archivos adjuntos.');
        return;
      }

      if (this.chequeAutorizar) {
        alert('Por favor acepte la política de tratamiento de datos personales.');
        return;
      } 

      this.modalMsg = 'Por favor espere mientras se guardan los datos..';
      this.show = true;
      
      if (this.add){
        this.item = {};

        this.item.cmp_id = this.$store.state.item.cmp_id;
        this.item.tcr_id = this.$store.state.item.tcr_id;
        this.item.tdo_id = this.$store.state.item.tdo_id;
        this.item.tta_id = this.$store.state.item.tta_id;
        this.item.tav_nmro = this.$store.state.item.tav_nmro;
        this.item.dep_id = this.$store.state.item.dep_id;
        this.item.ser_id = this.$store.state.item.ser_id;
        this.item.tpa_id = this.$store.state.item.tpa_id;
        this.item.usu_id = this.$store.state.item.usu_id;
        this.item.dcm_tres = this.$store.state.item.dcm_tres;
        
        for (var i = 0; i < this.tpopqrs.length; i++){
          if (this.pqrs.tpq_id == this.tpopqrs[i]._id){
            if (this.tpopqrs[i].tpq_tres){
              this.item.dcm_tres = this.tpopqrs[i].tpq_tres;
            }
            i = this.tpopqrs.length;
          }
        }

        var entidad = {};
        entidad.cmp_id = this.item.cmp_id;
        entidad.dcm_entidad = this.pqrs.dcm_entidad;
        entidad.dcm_tpoid = this.pqrs.dcm_tpoid;
        entidad.dcm_identificacion = this.pqrs.dcm_identificacion;
        entidad.dcm_ciudad = this.pqrs.dcm_ciudad;
        entidad.dcm_direccion = this.pqrs.dcm_direccion;
        entidad.dcm_telefono = this.pqrs.dcm_telefono;
        entidad.dcm_email = this.pqrs.dcm_email;

        let uri = '/entidades/addpqrs';
        this.axios.post(uri, entidad)
        .then((response) => {
          var now = new Date();
          this.item.dcm_fchaini = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          this.item.tso_id = this.pqrs.tso_id;
          this.item.ent_id = response.data.id; //Entidad
          this.item.dcm_entidad = this.pqrs.dcm_entidad;
          this.item.ciud_id = this.pqrs.ciud_id;
          this.item.dcm_ciudad = this.pqrs.dcm_ciudad;
          this.item.dcm_direccion = this.pqrs.dcm_direccion;
          this.item.dcm_email = this.pqrs.dcm_email;
          this.item.mdr_id = this.pqrs.mdr_id;
          this.item.dcm_asunto = this.pqrs.dcm_asunto;
          this.item.dcm_mensaje = this.pqrs.dcm_mensaje;
          this.item.tpq_id = this.pqrs.tpq_id;
          this.item.dcm_rspsta = true;
          this.item.dcm_web = true;
          this.item.dcm_resp_email = false;
          this.item.adjuntos = this.pqrs.adjuntos;

          /*
          this.item.indices = [];
          this.item.indices.push({ ind_id: this.item.pqr_tpoid, ixd_valor: this.pqrs.dcm_tpoid });
          this.item.indices.push({ ind_id: this.item.pqr_id, ixd_valor: this.pqrs.dcm_identificacion });
          this.item.indices.push({ ind_id: this.item.pqr_direccion, ixd_valor: this.pqrs.dcm_direccion });
          this.item.indices.push({ ind_id: this.item.pqr_telefono, ixd_valor: this.pqrs.dcm_telefono });
          this.item.indices.push({ ind_id: this.item.pqr_email, ixd_valor: this.pqrs.dcm_email });
          this.item.indices.push({ ind_id: this.item.pqr_mensaje, ixd_valor: this.pqrs.dcm_mensaje });
          */
          /*
          this.item.seguimientos = [];
          this.item.seguimientos.push({ ind_id: this.item.pqr_tpoid, ixd_valor: this.pqrs.dcm_tpoid });
          */

          this.item.adjuntos.push({});
          var adj_nmbre = 'pqrs.pdf'; // 'R-' + response.data.dcm_ano + '-' + response.data.dcm_cdgo + '.pdf';
          n = this.item.adjuntos.length-1;
          this.item.adjuntos[n]['adj_nmro'] = n + 1;
          this.item.adjuntos[n]['adj_nmbre'] = adj_nmbre;
          this.item.adjuntos[n]['usu_id'] = this.item.usu_id;
          this.item.adjuntos[n]['uploaded'] = '1';
          this.item.adjuntos[n]['temp'] = true;  
          this.item.dcm_expediente = this.$route.params.pqrs?._id || undefined

          // add item
          let uri = '/docs/add';
          this.axios.post(uri, { 'item': this.item })
          .then((response) => {
            this.add = false;
            //toastr.success(response.data.item, 'Response');

            var tid_cdgo = '';
            for (let i = 0; i < this.tpoids.length; i++){
              if (this.pqrs.dcm_tpoid == this.tpoids[i]._id){
                tid_cdgo = this.tpoids[i].tid_cdgo;
                i = this.tpoids.length;
              }
            }

            var id = response.data._id;

            let uri2 = '/api/pqrspdf';
            this.axios.post(uri2, {
              cmp_id: this.item.cmp_id,
              name: this.$store.state.companydata.cmp_nmbre,
              ciudad: this.$store.state.companydata.cmp_ciudad,
              usu_id: this.item.usu_id,
              adj_nmbre: adj_nmbre,
              pqrs: this.pqrs,
              dcm_fcharad: moment(response.data.dcm_fcharad).format('DD/MMM/YYYY hh:mm A'),
              dcm_ano: response.data.dcm_ano,
              dcm_cdgo: response.data.dcm_cdgo,
              logo: this.$store.state.companydata.cmp_logo,
              id: response.data._id,
              ts: this.timestamp,
              tid_cdgo: tid_cdgo
            })
            .then((response2) => {
              var { pages, size } = response2.data

              let urx = '/docs/update-pqrs-file';
              this.axios.post(urx, {
                id, adj_nmbre, pages, size
              }).then(resx => {
                // Save files
                let uri3 = '/api/savefiles/' + this.item.cmp_id + '/' + this.item.usu_id + '/' + response.data._id + '/' + this.timestamp;
                this.axios.post(uri3, this.item)
                //.then(wait(3000)) // DEV ONLY: wait for 1.5s
                .then((response3) => {
                  //this.search = { today: true };
                  if (this.close){
                    if (response3.data.ready){
                      var adjuntos = this.item.adjuntos.map(i => ({ path: './../repos/' + this.item.cmp_id+ '/temp/' + this.item.usu_id + '/' + this.timestamp + '/' + i.adj_nmbre }));

                      this.show = false;
                      this.$router.replace({ name: 'AcceptedPqrs', params: { tipo: this.item.tcr_id, ano: response.data.dcm_ano, numero: response.data.dcm_cdgo, email: this.pqrs.dcm_email, nombre: this.pqrs.dcm_entidad, id: response.data._id, adjuntos: adjuntos }});
                    } else {
                      this.message = 'Hubo un error al guardar los archivos.';
                      this.show = false;
                    }
                  }
                }).catch(err3 => {
                  this.message = '¡Error al guardar los archivos! ' + err3;
                  this.show = false;
                });          
              }).catch(errx => {
                this.message = '¡Error al guardar los archivos! ' + errx;
                this.show = false;
              });
            })
            .catch((err2) => {
              this.message = '¡Error al crear el radicado! ' + err2;
              this.show = false;
            });             
          })
          .catch((err) => {
            this.message = '¡Error al crear el registro! ' + err;
            this.show = false;
          });
        })
        .catch((err) => {
          this.message = '¡Error al crear la entidad! ' + err;
          this.show = false;
        });
      }
    },
    get_relation(id){
      let uri = '/docs/pqrs-relation/' + id;
      this.axios.get(uri).then(response=> {
        let data = response.data[0] || {}
        console.log("data: ", data)
        
        this.$set(this.pqrs, 'tso_id', data.tposolic._id);
        this.setTposolic({target: {value: data.tposolic._id}})

        this.$set(this.pqrs, 'dcm_tpoid', data.entidad.tid_id);
        this.$set(this.pqrs, 'dcm_identificacion', data.entidad.ent_numero);
        this.$set(this.pqrs, 'dcm_entidad', data.entidad.ent_nmbre);

        this.pqrs.dcm_ciudad = data.entidad.ent_ciudad
        this.pqrs.ciud_id = data.ciud_id
        this.fetchCiudadItem(data.ciud_id)

        this.$set(this.pqrs, 'dcm_telefono', data.entidad.ent_telefono);
        this.$set(this.pqrs, 'dcm_direccion', data.entidad.ent_direccion);
        this.$set(this.pqrs, 'dcm_email', data.entidad.ent_email);

        this.relation = true

      }).catch(error=> {
        console.log("Error get data relation pqr: ", error)
      })
    },
    getItem(id)
    {
      let uri = '/docs/edit/' + id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;

        this.files = this.item.adjuntos;

        for (var i = 0; i < this.files.length; i++){
          this.files[i].temp = false;
          this.files[i].uploaded = '0';
        }

        this.gettingItem = false;

      });
    },
    fetchTpoid(){
      let uri = '/tpoids/' + this.$store.state.company;
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.tid_nmbre > b.tid_nmbre) { return 1; };
          if (a.tid_nmbre < b.tid_nmbre) { return -1; };
          return 0;
        });
        this.tpoids = list;
      });
    },
    fetchTpopqrs(){
      let uri = '/tpopqrs/' + this.$store.state.company;
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;
      });
    },
    fetchTposolic(){
      let uri = '/tposolics/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tposolics = response.data;
      });
    },
    setTposolic(e){
      var t = e.target.value;
      console.log("t: ", t)
      this.tpoids2 = [];
      for (let i = 0; i < this.tposolics.length; i++){
        if (String(t) == String(this.tposolics[i]._id)){
          var tso = this.tposolics[i];
          for (let j = 0; j < tso.tpoids.length; j++){
            for (let k = 0; k < this.tpoids.length; k++){
              if (String(tso.tpoids[j].tid_id) == String(this.tpoids[k]._id)){
                this.tpoids2.push({ _id: this.tpoids[k]._id, tid_nmbre: this.tpoids[k].tid_nmbre });
                k = this.tpoids.length;
              }
            }
          }
          return;
        }
      }
    },
    fetchMedioresp(){
      let uri = '/medioresps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.medioresps = response.data;
      });
    },
    fetchTimeStamp(){
      let uri = '/api/timestamp';
      this.axios.post(uri)
      .then((response) => {
        this.timestamp = response.data.timestamp;
      })
      .catch((err) => {
        alert('No se pudo crear el identificador temporal. Consulte con el administrador.');
      });
    },
    addFiles(e) {
      //alert('Agregando archivos');
      var f = e.target.files;
      var data;
      var n;
      var m; //Indice de respuesta
      var version; //Versión de archivo
      var ext = '';
      var name = '';
      //var path = String(this.$store.state.company) + '/temp/' + this.$store.state.user + '/' + this.timestamp;
      var path = this.cmp + '/temp/' + this.usu + '/' + this.timestamp;

      if (f){
        this.ready = false;
        this.resetFilter();

        var c = this.files.length; // Número de archivos iniciales

        for (var i = 0; i < f.length; i++){
          version = 0;
          name = f[i].name;
          ext = f[i].name.substring(f[i].name.lastIndexOf('.'));
          for (var j = 0; j < this.files.length; j++){
            if (name == this.files[j].adj_nmbre){
              version++;
              name = f[i].name.substring(0, f[i].name.lastIndexOf('.')) + ' (' + version + ')' + ext;
            }
          }
          this.files.push({});
          n = this.files.length-1;
          this.files[n]['adj_nmro'] = n + 1;
          this.files[n]['adj_nmbre'] = name;
          this.files[n]['usu_id'] = this.usu;
          this.files[n]['usu_nmbre'] = this.usuname;
          this.files[n]['uploaded'] = '1';
          this.files[n]['temp'] = true;
          this.files[n]['percentage'] = 0;
          this.files[n]['download'] = false;
          this.files[n]['downloadPercentage'] = 0;
          this.$set(this.files, n, this.files[n]);

          //n++;
        }

        const uploadProgress = (id) => (progressEvent) => {
          this.files[id].percentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          this.$set(this.files, id, this.files[id]);
        }
        
        for (var i = 0; i < f.length; i++){
          data = new FormData();
          // Append file
          data.append('file', f[i], this.files[c+i].adj_nmbre);
          data.append('index', c + i);
          data.append('name', this.files[c+i].adj_nmbre);
          
          //Make the request to the POST /multiple-files URL
          this.axios.post('/api/upload/' + path,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: uploadProgress(c + i).bind(this)
            }
          )
          //.then(wait(5000))
          .then((response) => {
            let m = response.data.index;
            let { pages, size } = response.data

            this.files[m]['uploaded'] = '0';
            this.files[m]['adj_pages'] = pages
            this.files[m]['adj_size'] = size
            this.$set(this.files, m, this.files[m]);
            //console.log('SUCCESS!!');
          })
          .catch((err) => {
            let m = err.data.index;
            this.files[m]['uploaded'] = '2';
            this.$set(this.files, m, this.files[m]);
            //console.log('FAILURE!!');
          });

        }

        this.pqrs.adjuntos = this.files;
        this.ready = true;
        e.target.value = '';
      }
    },    
    deleteFile(i) {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let index = i;
        /*
        if (this.filenameFilter || this.tagnameFilter){
          for (let k = 0; k < this.item.adjuntos.length; k++){
            if (this.files[i].adj_nmbre == this.item.adjuntos[k].adj_nmbre){
              index = k;
              k = this.item.adjuntos.length;
              if (this.files[i].temp){
                this.files.splice(i,1);
              } else {
                this.files[i].delete = true;
                this.$set(this.files, i, this.files[i]);
              }
            }
          }
        }
        */
        this.pqrs.adjuntos.splice(index,1);
      }
    },
    downloadFile(adj_nmbre, temp, download, id){
      this.files[id].download = true;
      this.files[id].downloadPercentage = 0;
      this.$set(this.files, id, this.files[id]);

      const downloadProgress = (id) => (progressEvent) => {
        this.files[id].downloadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        this.$set(this.files, id, this.files[id]);
      }

      this.axios({
        url: '/api/download',
        method: 'POST',
        data: { 'cmp_id': this.cmp, '_id': this.pqrs._id, 'usu_cdgo': this.usu, 'adj_nmbre': adj_nmbre, 'temp': temp, 'download': download, 'ts': this.timestamp },
        responseType: 'blob',
        onDownloadProgress: downloadProgress(id).bind(this)            
      })
      //.then(wait(5000))
      .then((response) => {
          this.files[id].download = false;
          this.$set(this.files, id, this.files[id]);
          const url = window.URL.createObjectURL(new File([response.data], adj_nmbre, {type: 'application/octet-stream'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', adj_nmbre);
          //link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
      })
      .catch(err => {
          this.files[id].download = false;
          this.$set(this.files, id, this.files[id]);
      });
    },
    isPdf(adj_nmbre){
      if (adj_nmbre){
        var ext = adj_nmbre.substring(adj_nmbre.lastIndexOf('.'));
        if (ext.toLowerCase() == '.pdf'){
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    async viewPdf(adj_nmbre, temp, id){
      try {
        this.modalMsg = 'Por favor espere mientras se muestra el archivo..';
        this.show = true;

        this.pdfFile = {};
        let path = '/';
        var data = {       
            _id: this.item._id,
            cmp_id: this.cmp,
            path: path,
            name: encodeURIComponent(adj_nmbre)
        }

        if (temp) {
          data = {       
            ...data,
            user: this.usu,
            timestamp: this.timestamp,
            temp: temp
          }
        }

        let base_url = process.env.NODE_ENV === 'production' ? "" : "http://localhost:4000";

        const response = await this.axios({
          url: `${base_url}/filesystemprovider/pass-out`,
          method: 'POST',
          data
        });

        const token = response?.data?.tk;
        var url = `${base_url}/filesystemprovider/view-file?tk=${encodeURIComponent(token)}`;

        this.pdfFile = {
          url: url,
          adj_nmbre: adj_nmbre
        };

        $('#pdfModal').modal('show');
        this.show = false;
      } catch (error) {
        this.message = 'Error al mostrar el archivo.';
        this.show = false;
      }
    },
    percentageValue(p){
      return 'width:' + p + '%';
    },
    resetFilter(){
      this.files = this.pqrs.adjuntos;
    },
    clearPdf(){
      this.pdfFile.url = '';
    },
    tabPdf(){
      //const url = window.URL.createObjectURL(new File([response.data], adj_nmbre, {type: 'application/octet-stream'}));
      const link = document.createElement('a');
      link.href = this.pdfFile.url;
      //link.setAttribute('download', adj_nmbre);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    },
    setCiudad(i){
      console.log(i)
      let uri = '/ciudades/edit/' + String(i.id);
      this.axios.get(uri)
      .then((response) => {
        var ciudad = response.data;
        this.$set(this.pqrs, 'dcm_ciudad', ciudad.ciud_desc_mcp + ' (' + ciudad.ciud_desc_dep + ')');
      })
      .catch((err) => {
        alert('No se ha encontrado la ciudad');
      });
    },
    fetchCiudadItem(ciud_id){
        let uri = '/ciudades/edit/' + String(ciud_id);
        this.axios.get(uri).then((response) => {
          var data = response.data;          
          $('#ciud_id').append('<option value="' + data._id + '">' + data.ciud_desc_mcp + ' (' + data.ciud_desc_dep + ')' + '</option>');          
          $("#ciud_id").select2("trigger", "select", {
            data: { id: data._id }
          });          
        });
      },
  } //END METHODS  
}
</script>
